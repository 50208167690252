import './Download.css';

// Libraries
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import FieldSelection from '../FieldSelection/FieldSelection';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';
import Button from 'components/Buttons/Button/Button';
import IconButton from 'components/Buttons/IconButton/IconButton';
import Modal from 'components/Modal/Modal';

// Utils
import User from 'utils/user.util';
import { useQuery, useMutation, GET_SEGMENT, EXPORT_SEGMENT_RESULTS } from 'utils/graphql';

// Assets
import { ReactComponent as BackIcon } from 'assets/icons/back_icon_24.svg';
import { ReactComponent as ErrorIconBig } from 'assets/icons/error_icon.svg';

function Download() {
    let navigate = useNavigate();
    let { segment_id } = useParams();
    const [modalOptions, setModalOptions] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [selectedFields, setSelectedFields] = useState([]);
    const [validating, setValidating] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const { data: segmentData } = useQuery(GET_SEGMENT, {
        skip: !segment_id,
        variables: { id: segment_id },
    });

    const [exportSegmentResults] = useMutation(EXPORT_SEGMENT_RESULTS);

    const handleSelection = (selection) => {
        setSelectedFields(selection);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const openModal = (type) => {
        let modalOptions = {};
        switch (type) {
            case 'exportSuccess':
                modalOptions = {
                    title: 'CSV Export Started',
                    content:
                        'CSV export started successfully. You will receive an email with a download link as soon as the file is ready.',
                    width: '350px',
                    textAlign: 'center',
                };
                break;
            case 'exportError':
                modalOptions = {
                    title: <ErrorIconBig fill="var(--error-color)" />,
                    content: 'CSV export failed to start.',
                    width: '250px',
                    textAlign: 'center',
                };
                break;
            default:
                return;
        }
        setModalOptions(modalOptions);
        setIsModalOpen(true);
    };

    const download = async () => {
        setDownloading(true);
        const properties = selectedFields.map((field) => {
            const column = {
                table: field.tableProperty.tableName,
                property: field.tableProperty.propertyName,
            };
            if (field.aggregateFunction) {
                column.aggregateFunction = field.aggregateFunction;
            }
            if (field.tableProperty.joinPath) {
                column.joinPath = JSON.stringify(field.tableProperty.joinPath);
            }
            return column;
        });

        exportSegmentResults({
            variables: { segmentationId: segment_id, columns: properties, email: User.getEmail() },
            onCompleted: (data) => {
                if (data.exportSegmentationResults.success) {
                    openModal('exportSuccess');
                } else {
                    openModal('exportError');
                }
                setDownloading(false);
                setIsValid(false);
            },
            onError: (error) => {
                console.log({ error });
                openModal('exportError');
                setDownloading(false);
                setIsValid(false);
            },
        });
    };

    useEffect(() => {
        if (!isValid) return;
        setValidating(false);
        download();
    }, [isValid]);

    return (
        <div className="download-wrapper">
            <ContentPanel
                title={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div className="header-col">
                            Export Results to CSV
                            <div className="sub-header">
                                For segment <span>{segmentData?.segmentation?.name}</span>
                            </div>
                        </div>
                        <IconButton onClick={handleBackClick}>
                            <BackIcon height="24" width="24" fill="var(--font-color)" />
                        </IconButton>
                    </div>
                }
            >
                <div className="download-field-selection">
                    <FieldSelection
                        handleSelection={handleSelection}
                        segmentation={segmentData?.segmentation}
                        validate={validating}
                        setValidate={setValidating}
                        setIsValid={setIsValid}
                        hint={'Drag and drop fields here to include them in your CSV download.'}
                    />
                    <div className="download-btn-wrapper">
                        <Button
                            onClick={() => setValidating(true)}
                            isDisabled={downloading}
                            width="300px"
                        >
                            {downloading || validating ? <LoaderSecondary size="35px" /> : 'Export'}
                        </Button>
                    </div>
                </div>
            </ContentPanel>
            <Modal options={modalOptions} isOpen={isModalOpen} setOpen={setIsModalOpen} />
        </div>
    );
}

export default Download;
