import { client, gql } from './apollo';

const createFilterGroupMutationString = (filterGroup, updating = false) => {
    let mutationString = `operator: ${filterGroup.operator}`;
    if (updating && !filterGroup.isNew) {
        mutationString += `
        id: "${filterGroup.id}"`;
    }

    let queryFilters = '';
    let queryFilterGroups = '';
    for (const filter of filterGroup.filters) {
        if (filter.isFilter) {
            queryFilters += `{
                    ${updating && !filter.isNew ? `id: "${filter.id}"` : ''}
                    tablePropertyId: "${
                        filter.isNew
                            ? filter.tableProperty.propertyGlobalId
                            : filter.tableProperty.propertyId
                    }"
                    operator: "${filter.operator.text}"
                    value: "${filter.value}"
                    ${
                        (filter.filterType === 'Number' ||
                            filter.filterType === 'Date' ||
                            filter.filterType === 'Function') &&
                        filter.isFunctionSelected
                            ? `aggregateFunction: "${filter.aggregateFunction}"`
                            : ''
                    }
                    ${
                        // filter.joinPathId null check necessary to save an old segment without an existing join path
                        filter.joinPath
                            ? `joinPath: { 
                                ${
                                    updating && !filter.isNew && filter.joinPathId
                                        ? `id: "${filter.joinPathId}"`
                                        : ''
                                }
                                path: "${JSON.stringify(filter.joinPath).replace(/"/g, '\\"')}" 
                            }`
                            : ''
                    }
                    
                }`;
        } else {
            queryFilterGroups += `${createFilterGroupMutationString(filter, updating)}`;
        }
    }
    if (queryFilters.length > 0) {
        mutationString += `
            queryFilters: [
                ${queryFilters}
            ]
        `;
    }
    if (queryFilterGroups.length > 0) {
        mutationString += `
            queryFilterGroups: [
                ${queryFilterGroups}
            ]
        `;
    }
    return `{
        ${mutationString}
    }`;
};

const generateFilterGroupsStrings = (segmentation) => {
    let includeFilterGroup = '';
    let excludeFilterGroup = '';
    if (
        segmentation.includeFilterGroup.filters &&
        segmentation.includeFilterGroup.filters.length > 0
    ) {
        includeFilterGroup = `includeFilterGroup: ${createFilterGroupMutationString(
            segmentation.includeFilterGroup,
            segmentation.id
        )}`;
    }
    if (
        segmentation.excludeFilterGroup.filters &&
        segmentation.excludeFilterGroup.filters.length > 0
    ) {
        excludeFilterGroup = `excludeFilterGroup: ${createFilterGroupMutationString(
            segmentation.excludeFilterGroup,
            segmentation.id
        )}`;
    }
    return { includeFilterGroup, excludeFilterGroup };
};

const filterFieldsFragment = gql`
    fragment filterFields on QueryFilter {
        id
        tableProperty {
            id
            property
            type {
                id
                type
            }
            table {
                id
                name
            }
        }
        operator
        value
        aggregateFunction
        joinPath {
            id
            path
        }
    }
`;

const filterGroupFieldsFragment = gql`
    fragment filterGroupFields on QueryFilterGroup {
        id
        operator
        childrenQueryFilters {
            edges {
                node {
                    ...filterFields
                }
            }
        }
    }
    ${filterFieldsFragment}
`;

const filterGroupsRecursiveFragment = gql`
    fragment filterGroupsRecursive on QueryFilterGroup {
        childrenQueryFilterGroups {
            edges {
                node {
                    ...filterGroupFields
                    childrenQueryFilterGroups {
                        edges {
                            node {
                                ...filterGroupFields
                                childrenQueryFilterGroups {
                                    edges {
                                        node {
                                            ...filterGroupFields
                                            childrenQueryFilterGroups {
                                                edges {
                                                    node {
                                                        ...filterGroupFields
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${filterGroupFieldsFragment}
`;

export const GET_SEGMENT = gql`
    query segmentation($id: ID!) {
        segmentation(id: $id) {
            id
            name
            primaryTable {
                id
                name
                isJunction
            }
            includeFilterGroup {
                ...filterGroupFields
                ...filterGroupsRecursive
            }
            excludeFilterGroup {
                ...filterGroupFields
                ...filterGroupsRecursive
            }
            consent {
                id
            }
            consentJoinPath {
                id
                path
            }
            audits {
                edges {
                    node {
                        id
                        operation
                        createdAt
                        user {
                            id
                            lastName
                            firstName
                        }
                    }
                }
            }
        }
    }
    ${filterGroupFieldsFragment}
    ${filterGroupsRecursiveFragment}
`;

export const GET_SEGMENTS = gql`
    query (
        $offset: Int
        $limit: Int
        $search: String
        $exclude: [String]
        $isConsent: Boolean
        $orderBy: String
        $orderByDirection: GraphQLDirection
    ) {
        segmentationList(
            offset: $offset
            limit: $limit
            search: $search
            exclude: $exclude
            isConsent: $isConsent
            orderBy: $orderBy
            orderByDirection: $orderByDirection
        ) {
            edges {
                node {
                    id
                    name
                    isConsent
                    consent {
                        id
                        name
                    }
                    primaryTable {
                        id
                        name
                    }
                    audits {
                        edges {
                            node {
                                id
                                operation
                                createdAt
                                user {
                                    id
                                    lastName
                                    firstName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_CONSENTS_FOR_TABLE = gql`
    query ($id: ID!) {
        consentList(tableId: $id)
    }
`;

// Total number of segments
export const GET_SEGMENTATION_COUNT = gql`
    query ($isConsent: Boolean, $name: String) {
        segmentationListCount(isConsent: $isConsent, name: $name)
    }
`;

// This returns a count of Segments that start with the passed in name
// using iLike in backend
export const COUNT_SEGMENTS_BY_NAME = gql`
    query ($name: String!) {
        segmentationList(search: $name) {
            totalCount
        }
    }
`;

export const IS_SEGMENT_NAME_VALID = gql`
    query ($name: String!) {
        isSegmentationNameValid(name: $name)
    }
`;

export const DELETE_SEGMENT = gql`
    mutation ($id: ID!) {
        deleteSegmentation(id: $id) {
            id
        }
    }
`;

export const GET_SEGMENT_TABLE_TREE = gql`
    query ($tableId: String!) {
        segmentationTableTree(tableId: $tableId)
    }
`;

export const createSegment = async (segment, options = {}) => {
    const { includeFilterGroup, excludeFilterGroup } = generateFilterGroupsStrings(segment);
    return await client.mutate({
        mutation: gql`
                mutation($name: String!, $primaryTableId: ID!, $consentId: String, $isConsent: Boolean, $consentJoinPath: JoinPathInput) {
                    createSegmentation(
                        segmentation: {
                            name: $name
                            isConsent: $isConsent
                            primaryTableId: $primaryTableId
                            consentId: $consentId
                            consentJoinPath: $consentJoinPath
                            ${includeFilterGroup}
                            ${excludeFilterGroup}
                        }
                    ) {
                        segmentation {
                            id
                        }
                    }
                }
            `,
        variables: {
            name: segment.name,
            isConsent: segment.isConsent,
            primaryTableId: segment.primaryTableId,
            consentId: segment.consentId,
            consentJoinPath: segment.consentJoinPath
                ? {
                      path: JSON.stringify(segment.consentJoinPath),
                  }
                : null,
        },
        refetchQueries: options?.refetchQueries,
        onCompleted: options?.onCompleted ? options.onCompleted() : () => {},
    });
};

export const EXPORT_SEGMENT_RESULTS = gql`
    mutation ($segmentationId: ID!, $columns: [SegmentationColumnInput]!, $email: String!) {
        exportSegmentationResults(
            segmentationId: $segmentationId
            columns: $columns
            email: $email
        ) {
            success
            segmentationId
            columns {
                table
                property
                aggregateFunction
                joinPath
            }
        }
    }
`;

export const GET_SEGMENT_RESULTS_COUNT = gql`
    query ($segmentationId: ID!) {
        segmentationResultsCount(segmentationId: $segmentationId)
    }
`;

export const GET_ARE_SEGMENT_RESULTS_RUNNING = gql`
    query ($segmentationId: ID!) {
        segmentationAreResultsRunning(segmentationId: $segmentationId)
    }
`;

export const GET_SEGMENT_PREVIEW = gql`
    query ($segmentationId: ID!, $kpiId: ID!, $joinPath: String) {
        segmentationPreview(segmentationId: $segmentationId, kpiId: $kpiId, joinPath: $joinPath)
    }
`;

export const VALIDATE_SEGMENTATION_QUERY = gql`
    query ($segmentationId: ID!, $columns: [SegmentationColumnInput]) {
        segmentationQueryValidation(segmentationId: $segmentationId, columns: $columns)
    }
`;

export const updateSegment = async (segment, options = {}) => {
    const { includeFilterGroup, excludeFilterGroup } = generateFilterGroupsStrings(segment);
    return await client.mutate({
        mutation: gql`
            mutation ($id: ID!, $name: String!, $primaryTableId: ID!, $consentId: String, $consentJoinPath: UpdateJoinPathInput) {
                updateSegmentation(
                    segmentation: {
                        id: $id
                        name: $name
                        primaryTableId: $primaryTableId
                        consentId: $consentId
                        consentJoinPath: $consentJoinPath
                        ${includeFilterGroup}
                        ${excludeFilterGroup}
                    }
                ) {
                    segmentation {
                        id
                    }
                }
            }
        `,
        variables: {
            id: segment.id,
            name: segment.name,
            primaryTableId: segment.primaryTableId,
            consentId: segment.consentId,
            consentJoinPath: segment.consentJoinPath
                ? {
                      path: JSON.stringify(segment.consentJoinPath.path),
                      id: segment.consentJoinPath.id ? segment.consentJoinPath.id : null,
                  }
                : null,
        },
        refetchQueries: options?.refetchQueries ? [...options.refetchQueries] : [],
        onCompleted: options?.onCompleted ? options.onCompleted() : () => {},
    });
};
