import './DropArea.css';
import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';

function DropArea({ children, type, title, dropHandler, className = '', onIsOverChange = null }) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: type,
        drop: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (didDrop) return;
            dropHandler(item);

            return {
                name: title,
            };
        },
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item) => {
            if (item.type === type || type?.includes(item.type)) return true;
            return false;
        },
    });

    useEffect(() => {
        if (onIsOverChange) {
            onIsOverChange(isOver);
        }
    }, [isOver, onIsOverChange]);

    return (
        <div
            ref={drop}
            className={`drop-area ${className} ${
                canDrop && !onIsOverChange && isOver ? 'hover' : ''
            }`}
        >
            {children}
        </div>
    );
}

export default DropArea;
