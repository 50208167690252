import './Loader.css';
import { memo, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

const Loader = ({ size = 80 }) => {
    const uniqueId = useMemo(() => uuidv4(), []);

    const generateWavePath = (startY, midY, endY, waveWidth, step) => {
        let path = `M-${waveWidth},${startY} `;
        for (let x = -waveWidth + step; x <= size + waveWidth; x += step) {
            // determine alternation based on relative position
            const isEven = Math.floor((x + waveWidth) / step) % 2 === 0;
            const ctrlY = isEven ? midY : endY;
            path += `Q ${x - step / 2},${ctrlY} ${x},${startY} `;
        }
        path += `V${size} H-${waveWidth} Z`;
        return path;
    };

    const circleStrokeWidth = 2;
    const viewBoxPadding = circleStrokeWidth; // allow padding for the stroke
    const viewBox = `-${viewBoxPadding} -${viewBoxPadding} ${size + 2 * viewBoxPadding} ${
        size + 2 * viewBoxPadding
    }`;

    const circleRadius = size / 2 - circleStrokeWidth;
    const innerCircleRadius = circleRadius / 2;

    const duration = 4 + 120 / size;
    // dynamically adjust wave properties
    const waveStartYDenominator = 1.2;
    const step = Math.round(size / 5);
    const waveWidth = size * 5;
    const waveMidCalc = Math.min(size / 6, innerCircleRadius / 2); // height limited by innerCircleRadius
    const waveStartY = size / waveStartYDenominator;
    const waveMidY = waveStartY - waveMidCalc;
    const waveEndY = waveStartY + waveMidCalc;

    const wavePath = generateWavePath(waveStartY, waveMidY, waveEndY, waveWidth, step);

    return (
        <div className="okzou-loader">
            <svg
                width={size + 2 * viewBoxPadding}
                height={size + 2 * viewBoxPadding}
                viewBox={viewBox}
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    {/* using mask to have the background color of the parent component showing below the loader */}
                    <mask id={`circleMask-${uniqueId}`}>
                        <rect
                            x={-viewBoxPadding}
                            y={-viewBoxPadding}
                            width={size + 2 * viewBoxPadding}
                            height={size + 2 * viewBoxPadding}
                            fill="white"
                        />
                        {/* inner circle is masked out */}
                        <circle cx={size / 2} cy={size / 2} r={innerCircleRadius} fill="black" />
                        {/* animated wave is masked out */}
                        <path fill="black" stroke="black" d={wavePath}>
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="translate"
                                from="-150 0"
                                to="150 0"
                                dur={`${duration}s`}
                                repeatCount="indefinite"
                            />
                        </path>
                    </mask>
                </defs>
                {/* blue circle */}
                <circle
                    fill="#0075eb"
                    cx={size / 2}
                    cy={size / 2}
                    r={circleRadius}
                    stroke="#0075eb"
                    strokeWidth={circleStrokeWidth}
                    mask={`url(#circleMask-${uniqueId})`}
                />
            </svg>
        </div>
    );
};

export default memo(Loader);
