import './Flow.css';

// Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useFetch } from 'utils/rest/request';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import ContentPanel from 'components/ContentPanel/ContentPanel';
import FieldSelection from '../FieldSelection/FieldSelection';
import Input from 'components/Form/Input/Input';
import Loader from 'components/Loader/Loader';
import ScheduleCreator from './Scheduler/ScheduleCreator';
import Error from 'components/Error/Error';
import LoaderSecondary from 'components/LoaderSecondary/LoaderSecondary';
import Modal from 'components/Modal/Modal';
import Button from 'components/Buttons/Button/Button';
import IconButton from 'components/Buttons/IconButton/IconButton';
import Tooltip from 'components/Tooltip/Tooltip';
import InputError from 'components/Form/InputError/InputError';
import Dropdown from 'components/Form/Dropdown/Dropdown';

// Utils
import User from 'utils/user.util';
import { GET_SEGMENT, useQuery } from 'utils/graphql';

// Assets
import { ReactComponent as BackIcon } from 'assets/icons/back_icon_24.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check_icon_24.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_icon_24.svg';
import { ReactComponent as ErrorIconBig } from 'assets/icons/error_icon.svg';

function Flow() {
    const schedulerRef = useRef();
    let navigate = useNavigate();
    let { flow_id, segment_id } = useParams();
    const [flowName, setFlowName] = useState('');
    const [saving, setSaving] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalType, setModalType] = useState('success');
    const [modalOptions, setModalOptions] = useState();
    const [selectedAppConnection, setSelectedAppConnection] = useState();
    const [showSchedule, setShowSchedule] = useState(false);
    const [showFieldSelection, setShowFieldSelection] = useState(false);
    const [selectedFields, setSelectedFields] = useState([]);
    const [initSelectedFields, setInitSelectedFields] = useState([]);
    const [selectedRequirements, setSelectedRequirements] = useState({});
    const [selectedSchedule, setSelectedSchedule] = useState();
    const [checkingName, setCheckingName] = useState(false);
    const [checkNameTimeout, setCheckNameTimeout] = useState();
    const [validName, setValidName] = useState(-1);
    const [validationErrors, setValidationErrors] = useState();
    const [loadedFlow, setLoadedFlow] = useState();
    const [loadingFlow, setLoadingFlow] = useState(false);
    const [validatingFieldSelections, setValidatingFieldSelections] = useState(false);
    const [isValid, setIsValid] = useState(false);

    if (flow_id === 'new') {
        flow_id = null;
    }
    const appColors = [
        { bg: '#0F0F0F', font: 'white' },
        { bg: '#575757', font: 'white' },
        { bg: '#CFF800', font: 'black' },
    ];

    const {
        loading,
        data: appConnections,
        error,
    } = useFetch('/api/connected_apps/tenant/connections', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const { data: segmentData } = useQuery(GET_SEGMENT, {
        skip: !segment_id,
        variables: { id: segment_id },
    });

    const loadExistingFlow = async (flow_id) => {
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/connected_apps/tenant/routines?id=${flow_id}`,
            {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + User.getToken(),
                    'Content-Type': 'application/json',
                },
            }
        ).then((res) => res.json());

        const flow = response.rows[0];
        setLoadedFlow(flow);
    };

    const renderLogo = (app, index) => {
        const name = app.name.substr(0, 1).toUpperCase() + app.name.substr(1, 1).toLowerCase();
        const logo = app.connection_dependencies.find((e) => e.field === 'LOGO')?.value;

        if (logo) {
            // The nested app-icon is necessary when using dangerouslySetInnerHTML because it
            // cannot have any children
            return (
                <div>
                    <div className="app-icon" dangerouslySetInnerHTML={{ __html: logo }}></div>
                </div>
            );
        }

        return (
            <div
                className="app-icon"
                style={{
                    backgroundColor: appColors[index].bg,
                    color: appColors[index].font,
                }}
            >
                {name}
            </div>
        );
    };

    const getNameStatus = () => {
        if (checkingName) {
            return <Loader size={30} />;
        } else if (validName === 0) {
            return (
                <Tooltip
                    tip="Flow name already exists, name must be unique"
                    width="200px"
                    position="right-center"
                >
                    <ErrorIcon fill="var(--error-color)" />
                </Tooltip>
            );
        } else if (validName === 1) {
            return (
                <Tooltip tip="Valid name" position="right-center">
                    <CheckIcon fill="var(--success-color)" />
                </Tooltip>
            );
        }
    };

    const getFieldsAndDependencies = () => {
        let _fields = [];
        if (selectedAppConnection.app?.dependencies?.length > 0) {
            _fields = selectedAppConnection.app.dependencies
                .map((dependency) => {
                    const _selectedField = selectedFields.find(
                        (f) => dependency.id === f.requiredFieldId
                    );
                    return {
                        ..._selectedField,
                        dependency_id: dependency.id,
                    };
                })
                .filter((f) => f.id != null);
        } else {
            _fields = selectedFields;
        }

        return _fields;
    };

    const canContinue = () => {
        let errors = {};

        // Check flow name is unique and greater or equal to 3 characters
        const _name = flowName.trim();
        if (_name.length < 3 || validName === 0) {
            errors['name'] = 'Name must be unique and be at least 3 characters';
        }

        if (validName === -1) {
            errors['name'] = 'Name must be unique and validated';
        }

        // Check if an app is selected
        if (!selectedAppConnection) {
            errors['app'] = 'Selecting an app is required';
        }

        setValidationErrors(errors);

        if (Object.keys(errors).length) {
            return false;
        }

        return true;
    };

    const handleFieldSelection = (selection) => {
        setSelectedFields(selection);
    };

    const handleScheduleClick = () => {
        setShowSchedule(true);
    };

    const handleRunOnce = () => {
        // Todo: schedule for in 5 minutes, 1 occurrence
    };

    const handleFieldSelectionClick = () => {
        // Fetch selection from child component ScheduleCreator
        const selection = schedulerRef.current.getSelection();
        setSelectedSchedule(selection);
        if (canContinue()) {
            setShowFieldSelection(true);
        }
    };

    const handleAppSelection = (connection) => {
        if (selectedAppConnection?.id === connection?.id) {
            setSelectedAppConnection();
        } else {
            setSelectedAppConnection(connection);
        }

        let _errors = { ...validationErrors };
        delete _errors?.app;
        setValidationErrors(_errors);
    };

    const save = async () => {
        // Check if any requirements, if required and append to payload
        let payloadRequirements = {};
        const requirements = selectedAppConnection?.meta_data?.requirements;
        if (requirements) {
            for (const requirement of requirements) {
                if (requirement?.required === true && !selectedRequirements[requirement.id]) {
                    return setValidationErrors({ requirements: { [requirement.id]: 'error' } });
                } else {
                    payloadRequirements[requirement.id] = selectedRequirements[requirement.id];
                }
            }
        }

        const _fields = getFieldsAndDependencies();

        setSaving(true);

        const payload = {
            name: flowName,
            tenant_app_connection_id: selectedAppConnection.id,
            source_id: segment_id,
            start: selectedSchedule.form.start_date,
            end: (() => {
                if (selectedSchedule.form.ends === 'date') {
                    return selectedSchedule.form.ends_specific_date;
                }

                return null;
            })(),
            occurences_left: (() => {
                if (selectedSchedule.form.ends === 'occurences') {
                    return selectedSchedule.form.ends_occurences;
                }

                return null;
            })(),
            frequency:
                selectedSchedule.cron.minute +
                ' ' +
                selectedSchedule.cron.hour +
                ' ' +
                selectedSchedule.cron.day_month +
                ' ' +
                selectedSchedule.cron.month +
                ' ' +
                selectedSchedule.cron.day_week,
            data: {
                fields: _fields,
                form: selectedSchedule.form,
                cron: selectedSchedule.cron,
                requirements: payloadRequirements,
            },
        };

        const method = flow_id ? 'PATCH' : 'POST'; // Toggle between create and update
        const url_flow_id = flow_id ? flow_id : ''; // Toggle between create and update
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/connected_apps/tenant/routines/${url_flow_id}`,
            {
                method,
                body: JSON.stringify(payload),
                headers: {
                    Authorization: 'Bearer ' + User.getToken(),
                    'Content-Type': 'application/json',
                },
            }
        ).then((res) => res.json());

        if (response.id) {
            setTimeout(() => {
                setModalType('success');
                setIsOpen(true);

                setSaving(false);
            }, 2000);
        } else {
            setModalType('error');
            setIsOpen(true);
            setSaving(false);
        }

        setValidationErrors(null);
    };

    const handleBackClick = () => {
        if (showFieldSelection) {
            setShowFieldSelection(false);
        } else if (showSchedule) {
            setShowSchedule(false);
        } else {
            navigate(`/segmentation`);
        }
    };

    const getModalOptions = (type) => {
        switch (type) {
            case 'success':
                return {
                    title: 'Success',
                    content: (
                        <div>
                            <CheckIcon fill="green" />
                            <p>Flow Saved Successfully.</p>
                        </div>
                    ),
                    width: '250px',
                    textAlign: 'center',
                    closeBtnAction: () => {
                        window.location.href = '/segmentation';
                    },
                };
            case 'error':
                return {
                    title: 'Error',
                    content: <ErrorIconBig fill="var(--error-color)" />,
                    width: '250px',
                    textAlign: 'center',
                };
            case 'delete-flow':
                return {
                    type: 'confirmation',
                    title: 'Warning',
                    content: (
                        <div>
                            <p>
                                Are you sure you want to delete this Object and all it's data? You
                                will not be able to recover this afterwards.
                            </p>
                            <b>You're about to delete {flowName}</b>
                        </div>
                    ),
                    confirmBtnText: 'Yes',
                    cancelBtnText: 'No',
                    width: '250px',
                    textAlign: 'center',
                    confirmationBtnAction: modalOptions?.onConfirm,
                };
            default:
                break;
        }
    };

    const renderFlowSetup = () => {
        return (
            <div className="new-flow">
                <div className="section">
                    <div className="section-header">Flow name</div>
                    <div className="section-header-name">
                        <Input
                            height="38px"
                            width="360px"
                            value={flowName}
                            setValue={(e) => setFlowName(e.target.value)}
                            variant="light"
                            error={validationErrors?.name}
                            displayError={validationErrors?.name?.length > 0}
                        />
                        <div className="section-header-name-status">{getNameStatus()}</div>
                    </div>
                </div>
                <div className="section">
                    <div className="section-header">Select App</div>
                    <div className="app-select">
                        {loading && <Loader />}
                        {error
                            ? '=('
                            : appConnections?.rows?.map((connection, i) => {
                                  if (!connection?.app.status === 'active') return;
                                  return (
                                      <div
                                          className={`app-select-item${
                                              selectedAppConnection?.id === connection.id
                                                  ? ' selected'
                                                  : ''
                                          }`}
                                          key={i}
                                          onClick={() => handleAppSelection(connection)}
                                      >
                                          {renderLogo(connection.app, i)}
                                          <p>{connection.name}</p>
                                      </div>
                                  );
                              })}
                    </div>
                    {validationErrors?.app && <InputError error={validationErrors?.app} />}
                </div>
                <div className="schedule-header">Set a Schedule</div>
                <div className="section">
                    {showSchedule && (
                        <>
                            <ScheduleCreator
                                initValues={selectedSchedule?.form}
                                ref={schedulerRef}
                            />
                            <div className="flow-setup-btns">
                                <button className="btn" onClick={handleFieldSelectionClick}>
                                    Next
                                </button>
                                {flow_id && (
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            setModalType('delete-flow');
                                            setModalOptions({
                                                onConfirm: () => {
                                                    fetch(
                                                        `${process.env.REACT_APP_BACKEND_URL}/api/connected_apps/tenant/routines/${flow_id}`,
                                                        {
                                                            method: 'DELETE',
                                                            headers: {
                                                                Authorization:
                                                                    'Bearer ' + User.getToken(),
                                                                'Content-Type': 'application/json',
                                                            },
                                                        }
                                                    )
                                                        .then((res) => res.json())
                                                        .then((res) => {
                                                            if (res?.success) {
                                                                navigate('/segmentation');
                                                            }
                                                        })
                                                        .catch(() => {
                                                            // Todo
                                                            // openModal('objectDeleteError');
                                                        });
                                                },
                                            });
                                            setIsOpen(true);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                    {!showSchedule && (
                        <div className="schedule-select">
                            <Button
                                className="schedule-btn"
                                width="175px"
                                onClick={handleScheduleClick}
                            >
                                Set a Schedule
                            </Button>
                            {/* <Button width="175px" variant="secondary" onClick={handleRunOnceClick}>
                                Run Once
                            </Button> */}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderFieldSelection = () => {
        return (
            <div style={{ padding: '30px', backgroundColor: 'white' }}>
                {selectedAppConnection?.meta_data?.requirements && (
                    <div className="meta-data-section">
                        <h2>Requirements</h2>
                        {selectedAppConnection?.meta_data?.requirements?.map((req, i) => {
                            if (req.type === 'select') {
                                return (
                                    <div
                                        className="meta-data-section-requirement"
                                        key={`meta_data_requirement_${i}`}
                                    >
                                        <p key={`meta_data_name_${i}`}>{req.name}</p>
                                        <Dropdown
                                            key={`meta_data_dropdown_${i}`}
                                            value={selectedRequirements?.[req.id] || ''}
                                            values={[
                                                { text: 'selection', value: '', disabled: true },
                                            ].concat(
                                                req.options.map((o) => {
                                                    return {
                                                        text: `${o.description} (${o.value})`,
                                                        value: o.value,
                                                    };
                                                })
                                            )}
                                            setValue={(e) => {
                                                setSelectedRequirements({
                                                    ...selectedRequirements,
                                                    [req.id]: e.target.value,
                                                });
                                            }}
                                        />
                                        {validationErrors?.requirements?.[req.id] && (
                                            <div style={{ marginLeft: '5px' }}>
                                                <ErrorIcon
                                                    fill="var(--error-color)"
                                                    width="40"
                                                    height="40"
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}
                <FieldSelection
                    segmentation={segmentData?.segmentation}
                    initSelectedFields={initSelectedFields}
                    handleSelection={handleFieldSelection}
                    requiredFields={selectedAppConnection?.app.dependencies}
                    validate={validatingFieldSelections}
                    setValidate={setValidatingFieldSelections}
                    setIsValid={setIsValid}
                    hint={'Drag and drop fields into the required field slots below.'}
                />
                <div className="save-btn-wrapper">
                    <Button
                        width="300px"
                        onClick={() => setValidatingFieldSelections(true)}
                        disabled={saving}
                    >
                        {saving || validatingFieldSelections ? (
                            <LoaderSecondary size="35px" />
                        ) : showSchedule ? (
                            'Save'
                        ) : (
                            'Run'
                        )}
                    </Button>
                </div>
            </div>
        );
    };

    /**
     * Check if name is unique.
     */
    useEffect(() => {
        if (checkNameTimeout) clearTimeout(checkNameTimeout);
        if (flowName?.trim().length < 3) {
            setValidName(-1);
            setCheckingName(false);
            return;
        }

        // Skip checking name if flow name matches
        // the saved one.
        if (loadedFlow?.name === flowName) return;

        // Remove any previous validation errors
        let _errors = { ...validationErrors };
        delete _errors?.name;
        setValidationErrors(_errors);

        setCheckingName(true);
        const t = setTimeout(() => {
            fetch(
                process.env.REACT_APP_BACKEND_URL +
                    '/api/connected_apps/tenant/routines?name=' +
                    flowName.trim(),
                {
                    method: 'GET',
                    headers: {
                        Authorization: 'Bearer ' + User.getToken(),
                        'Content-Type': 'application/json',
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    if (res?.rows?.[0]?.name.toLowerCase() === flowName.trim().toLowerCase()) {
                        // Bad - Exists
                        setValidName(0);
                    } else {
                        // Good
                        setValidName(1);
                    }
                    setCheckingName(false);
                })
                .catch((e) => setCheckingName(false));
        }, 1000);

        setCheckNameTimeout(t);
    }, [flowName]);

    /**
     * Load existing flow.
     */
    useEffect(() => {
        if (!flow_id) return;
        setLoadingFlow(true);
        loadExistingFlow(flow_id);
    }, [flow_id]);

    /**
     * Effect when existing flow has been loaded.
     * Fill all necessary fields.
     */
    useEffect(() => {
        if (!loadedFlow) return;

        // Set name
        setFlowName(loadedFlow.name);
        setValidName(1);

        // Set selected app
        setSelectedAppConnection(loadedFlow.connection);

        // Set schedule
        setSelectedSchedule({
            form: loadedFlow.data.form,
            cron: loadedFlow.data.cron,
        });
        setShowSchedule(true);

        // Set selected fields
        setInitSelectedFields(loadedFlow.data.fields);

        // Set initial selected requirements, if any
        setSelectedRequirements(loadedFlow?.data.requirements || {});

        setLoadingFlow(false);
    }, [loadedFlow]);

    useEffect(() => {
        if (!isValid) return;
        setValidatingFieldSelections(false);
        if (showSchedule) {
            save();
        } else {
            handleRunOnce();
        }
    }, [isValid]);

    return (
        <div className="flow-wrapper">
            {segment_id && !loadingFlow && (
                <ContentPanel
                    title={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className="header-col">
                                {!flow_id ? 'Create New' : 'Edit'} Flow
                                <div className="sub-header">
                                    For segment <span>{segmentData?.segmentation?.name}</span>
                                </div>
                            </div>
                            <IconButton onClick={handleBackClick} isDisabled={saving}>
                                <BackIcon height="24" width="24" fill="var(--font-color)" />
                            </IconButton>
                        </div>
                    }
                >
                    {!showFieldSelection ? renderFlowSetup() : renderFieldSelection()}
                </ContentPanel>
            )}
            {segment_id && loadingFlow && (
                <ContentPanel
                    style={{
                        width: '100%',
                        minHeight: '200px',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                    }}
                >
                    <Loader />
                </ContentPanel>
            )}
            {!segment_id && (
                <ContentPanel>
                    <Error />
                </ContentPanel>
            )}
            <Modal options={getModalOptions(modalType)} isOpen={modalIsOpen} setOpen={setIsOpen} />
        </div>
    );
}

export default Flow;
