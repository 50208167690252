import Button from 'components/Buttons/Button/Button';
import './Modal.css';

// Libraries
import React from 'react';
import { default as ReactModal } from 'react-modal';
import PropTypes from 'prop-types';

function Modal({ isOpen, setOpen, options = {} }) {
    const {
        type = 'info',
        title = 'title',
        content = '',
        footer = '',
        width = '400px',
        minWidth = '0',
        textAlign = 'left',
        confirmBtnText = 'Ok',
        cancelBtnText = 'Cancel',
        closeBtnAction = () => {},
        confirmationBtnAction = () => {},
        className = '',
    } = options;

    ReactModal.setAppElement('#root');

    const style = {
        overlay: {
            zIndex: '10',
        },
        content: {
            width: width,
            minWidth: minWidth,
            textAlign: textAlign,
        },
    };

    return (
        <ReactModal
            className={`modal ${className}`}
            isOpen={isOpen}
            style={style}
            onRequestClose={() => setOpen(false)}
        >
            <>
                <h2>{title}</h2>
                <div className="modal-content">{content}</div>
                <>
                    {type === 'custom' && footer}
                    {type === 'confirmation' && (
                        <div className="confirmation-btn-row">
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                {cancelBtnText}
                            </Button>
                            <Button
                                onClick={() => {
                                    const { cancel = false } = confirmationBtnAction() || {};
                                    if (!cancel) {
                                        setOpen(false);
                                    }
                                }}
                            >
                                {confirmBtnText}
                            </Button>
                        </div>
                    )}
                    {type === 'info' && (
                        <div className="info-btn-row">
                            <Button
                                onClick={() => {
                                    closeBtnAction();
                                    setOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    )}
                </>
            </>
        </ReactModal>
    );
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    options: PropTypes.shape({
        type: PropTypes.oneOf(['info', 'confirmation', 'custom']),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        footer: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        width: PropTypes.string,
        minWidth: PropTypes.string,
        textAlign: PropTypes.string,
        confirmBtnText: PropTypes.string,
        cancelBtnText: PropTypes.string,
        closeBtnAction: PropTypes.func,
        confirmationBtnAction: PropTypes.func,
        className: PropTypes.string,
    }).isRequired,
};

export default Modal;
